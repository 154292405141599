<template>
  <div class="main">
    <div
      style="color: red; padding-top: 30px; font-weight: bold; fong-size: 18px"
    >
      <p>
        通知:2021年全国足球特色幼儿园申报补录已开启，截止时间12月2日24：00，请补录园所在规定时间内，完成相关操作。如有疑问，请拨打400-831-9799咨询。
      </p>
    </div>
    <div class="step1" v-if="curStep === 'step1'">
      <div class="title">申报须知</div>
      <div class="cont">
        <p class="cont-tit">只要您的园所满足以下条件</p>
        <p class="cont-paragraph">
          1.经政府部门备案及审批通过的学前办学机构，且有意愿成为2021年全国足球特色幼儿园的园所。
        </p>
        <p class="cont-paragraph">2.具备开展幼儿足球活动硬件设施及师资要求。</p>
        <p class="cont-paragraph">
          3.园所以《全国足球特色幼儿园游戏活动指南》为纲，开展幼儿足球的教学工作。
        </p>
        <p class="cont-paragraph">
          4.自愿在系统平台注册成功并完善相关信息的幼儿园，完成实名认证、班级、教师及全园幼儿信息录入。
        </p>
        <p class="cont-paragraph">
          5.园所在幼儿足球游戏教学活动中，不得出现八项负面清单内容：
        </p>
        <p class="cont-paragraph" style="text-indent: 1em">
          禁止进行正式足球比赛；禁止幼儿足球考级；禁止足球操等形式化等表演；禁止全国足特幼儿园只练足球；
        </p>
        <p class="cont-paragraph" style="text-indent: 1em">
          禁止进行成人化、专业化、小学化的足球训练；禁止进行足球的头球练习；禁止所有负重的力量练习；严格控制运动时长和运动的强度；
        </p>
        <p class="cont-tit mt30">
          特别提醒<span class="manual"
            ><img src="../../../assets/img/park/Icon_Btn_Czzn.png" /><a
              target="_blank"
              href="/2021全国足球特色幼儿园申报操作指南.pdf"
              >操作指南</a
            ></span
          >
        </p>
        <p class="cont-paragraph">
          1.此评审标准要求申报园所，必须如实填报相关信息，通过评选标准认定全国足特园资格，如后期发现有不实填报、违规园所，则全国校足办按
          规定取消其全国足球特色幼儿园资格。
        </p>
        <p class="cont-paragraph">
          2.足特园申报需要使用园长账号登录平台讲行申报。
        </p>
        <p class="cont-tit mt30">平台申报条件审核结果</p>
        <p
          class="cont-demand"
          :class="shiming == 0 || shiming == 2 ? 'cont-undemand' : ''"
          @click="renzhneg"
        >
          1.{{ certificationList[shiming] }}
        </p>
        <p
          class="cont-demand"
          :class="{ 'cont-undemand noPointer': !isWhiteList }"
        >
          2.{{
            isWhiteList ? "园所不在禁止申报名单中。" : "园所不具备申报资格。"
          }}
        </p>
      </div>
      <div class="start-declare">
        <div class="declare-check" @click="checkStatus = !checkStatus">
          <span :class="checkStatus ? 'active' : ''"></span
          >我承诺将按照申报要求如实填报
        </div>
        <div
          :class="
            isWhiteList && (shiming == 88 || shiming == 1) && checkStatus
              ? 'start-declare-btn'
              : 'undeclare'
          "
          @click="startDeclare"
        >
          开始申报
        </div>
      </div>
    </div>
    <div class="step2" v-if="curStep === 'step2'">
      <div class="title">申报填写</div>
      <div class="form">
        <p class="form-tit">教学理念</p>
        <el-row :gutter="40" class="form-row">
          <el-col :span="12">
            <div class="form-item">
              <p class="form-item-tit"><span>*</span>1.阐述幼儿足球活动理念</p>
              <p class="form-item-inp">
                <el-input
                  v-model="rulesForm1.teachingConcept"
                  placeholder="请阐述幼儿足球活动理念"
                  type="textarea"
                  :rows="1"
                  :readonly="jinyong"
                  :disabled="jinyong"
                  @blur="sure('teachingConcept', rulesForm1.teachingConcept)"
                ></el-input>
              </p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="form-item">
              <p class="form-item-tit">
                <span>*</span>2.园所在幼儿足球教学中是否遵循八项负面清单的要求
              </p>
              <p class="form-item-inp">
                <el-select
                  v-model="rulesForm1.isRules"
                  placeholder="请选择"
                  @change="sure('isRules', rulesForm1.isRules)"
                  :readonly="jinyong"
                  :disabled="jinyong"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in isRulesList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </p>
            </div>
          </el-col>
        </el-row>
        <p class="form-tit mt30">设施与师资</p>
        <el-row :gutter="40" class="form-row">
          <el-col :span="12">
            <div class="form-item">
              <p class="form-item-tit">
                <span>*</span
                >1.阐述体育场地的情况(运动场面积、场地类型、规格及数量)
              </p>
              <p class="form-item-inp">
                <el-input
                  v-model="rulesForm1.siteConditions"
                  placeholder="请阐述体育场地的情况(运动场面积、场地类型、规格及数量)"
                  type="textarea"
                  :rows="1"
                  @blur="sure('siteConditions', rulesForm1.siteConditions)"
                  :readonly="jinyong"
                  :disabled="jinyong"
                ></el-input>
              </p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="form-item">
              <p class="form-item-tit">
                <span>*</span>2.填写配备电子显示屏的活动室数量
              </p>
              <p class="form-item-inp">
                <el-input-number
                  @change="sure('roomNum', rulesForm1.roomNum)"
                  :readonly="jinyong"
                  :disabled="jinyong"
                  placeholder="请填写配备电子显示屏的活动室数量"
                  v-model="rulesForm1.roomNum"
                  controls-position="right"
                  :min="0"
                  :max="99"
                  style="width: 100%"
                ></el-input-number>
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row class="form-row">
          <el-col :span="24">
            <div class="form-item">
              <p class="form-item-tit">
                <span>*</span>3.阐述幼儿足球活动器材、教具等配备情况
              </p>
              <p class="form-item-inp">
                <el-input
                  v-model="rulesForm1.footballEquipment"
                  placeholder="请阐述幼儿足球活动器材、教具等配备情况"
                  type="textarea"
                  :rows="1"
                  :readonly="jinyong"
                  :disabled="jinyong"
                  @blur="
                    sure('footballEquipment', rulesForm1.footballEquipment)
                  "
                ></el-input>
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row class="form-row">
          <el-col :span="24">
            <div class="form-item">
              <p class="form-item-tit">
                <span>*</span
                >4.阐述师资配备情况(填写教师总数量及具备幼儿足球教师数量及参与进修、培训等情况说明)
              </p>
              <p class="form-item-inp">
                <el-input
                  v-model="rulesForm1.teachingSituation"
                  placeholder="请阐述师资配备情况"
                  type="textarea"
                  :rows="1"
                  :readonly="jinyong"
                  :disabled="jinyong"
                  @blur="
                    sure('teachingSituation', rulesForm1.teachingSituation)
                  "
                ></el-input>
              </p>
            </div>
          </el-col>
        </el-row>
        <p class="form-tit mt30">教学开展记录</p>
        <el-row class="form-row">
          <el-col>
            <div class="form-item">
              <p class="form-item-tit">
                <span>*</span>1.阐述幼儿足球教学方式，并对教学活动进行记录
              </p>
              <p class="form-item-inp">
                <el-input
                  v-model="rulesForm1.teachingRecords"
                  placeholder="请阐述幼儿足球教学方式，并对教学活动进行记录"
                  type="textarea"
                  :rows="1"
                  :readonly="jinyong"
                  :disabled="jinyong"
                  @blur="sure('teachingRecords', rulesForm1.teachingRecords)"
                ></el-input>
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="40" class="form-row">
          <el-col :span="12">
            <div class="form-item">
              <p class="form-item-tit">2.上传幼儿教学活动记录(照片以及视频)</p>
              <p class="form-item-inp">
                <el-upload
                  :action="
                    constant.UPLOAD_URL +
                    '/footballFile/upload/' +
                    this.$store.state.userInfo.schoolId
                  "
                  :readonly="jinyong"
                  :disabled="jinyong"
                  :headers="{ token: $store.state.userInfo.token }"
                  list-type="picture-card"
                  :auto-upload="true"
                  :on-change="handleChange"
                  :limit="3"
                  :show-file-list="true"
                  :file-list="fileList"
                  :on-remove="handleRemove"
                  :on-success="handlesuccess"
                  :on-exceed="handleImageExceed"
                  :model="rulesForm1.teachingImages"
                  :before-upload="beforeAvatarUpload"
                  :on-preview="handlePictureCardPreview"
                  accept=".png,.jpg,.jpeg,.gif"
                >
                  <img
                    src="../../../assets/img/shenqing/Pic_Add_Photo.png"
                    alt=""
                  />
                </el-upload>
              </p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="form-item">
              <p class="form-item-tit" style="height: 21px"></p>
              <p class="form-item-inp">
                <!-- 上传视频-->
                <el-upload
                  ref="upload"
                  :readonly="jinyong"
                  :disabled="jinyong"
                  :limit="3"
                  :headers="{ token: $store.state.userInfo.token }"
                  class="upload-demo"
                  :action="
                    constant.UPLOAD_URL +
                    '/vod/video/footballUploadVideo/' +
                    this.$store.state.userInfo.schoolId
                  "
                  :file-list="fileVideo"
                  list-type="picture-card"
                  :before-upload="beforeAvatarUploadvideo"
                  :on-remove="handleRemovevideo"
                  :on-success="handleVideosuccess"
                  :on-exceed="handleVideoExceed"
                  :show-file-list="true"
                >
                  <img
                    src="../../../assets/img/shenqing/Pic_Add_Mov.png"
                    alt=""
                  />
                </el-upload>
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="40" class="form-row">
          <el-col :span="12">
            <div class="form-item">
              <p class="form-item-tit">
                <span>*</span>3.列举园所获取对幼儿足球荣誉
              </p>
              <p class="form-item-inp">
                <el-input
                  v-model="rulesForm1.schoolHonor"
                  placeholder="请列举园所获取对幼儿足球荣誉"
                  type="textarea"
                  :rows="1"
                  :readonly="jinyong"
                  :disabled="jinyong"
                  @blur="sure('schoolHonor', rulesForm1.schoolHonor)"
                ></el-input>
              </p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="form-item">
              <p class="form-item-tit">
                <span>*</span>4.列举园所在幼儿足球教学上的经费投入
              </p>
              <p class="form-item-inp">
                <el-input
                  v-model="rulesForm1.footballMoney"
                  placeholder="请列举园所在幼儿足球教学上的经费投入"
                  type="textarea"
                  :rows="1"
                  :readonly="jinyong"
                  :disabled="jinyong"
                  @blur="sure('footballMoney', rulesForm1.footballMoney)"
                ></el-input>
              </p>
            </div>
          </el-col>
        </el-row>
        <p class="form-tit mt30">教学规划</p>
        <el-row :gutter="40" class="form-row">
          <el-col :span="12">
            <div class="form-item">
              <p class="form-item-tit">
                <span>*</span>1.阐述本学年度园所幼儿足球教学规划
              </p>
              <p class="form-item-inp">
                <el-input
                  v-model="rulesForm1.schoolPlan"
                  placeholder="请阐述本学年度园所幼儿足球教学规划"
                  type="textarea"
                  :rows="1"
                  :readonly="jinyong"
                  :disabled="jinyong"
                  @blur="sure('schoolPlan', rulesForm1.schoolPlan)"
                ></el-input>
              </p>
              <input
                style="display: block; width: 0px; height: 0px; opacity: 0"
                placeholder=""
                resize="none"
              />
            </div>
          </el-col>
        </el-row>
        <div class="step-btn">
          <div class="step-btn-pre" @click="declarePre">上一步</div>
          <div class="step-btn-next" @click="toConfirm">下一步</div>
        </div>
      </div>
    </div>
    <div class="step3" v-if="curStep === 'step3'">
      <div class="title">申报确认</div>
      <div class="cont">
        <div class="cont-tit"><span></span>园所基础信息</div>
        <div class="cont-info">
          <el-row :gutter="20" class="form-row">
            <el-col :span="6">
              <div class="form-item">
                <span>幼儿园名称:</span>
                <span>{{ schoollist.schoolName }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>幼儿园编码:</span>
                <span>{{ schoollist.sn }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40" class="form-row">
            <el-col>
              <div class="form-item">
                <span>幼儿园地址:</span>
                <span>{{ schoollist.city }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="form-row">
            <el-col :span="6">
              <div class="form-item">
                <span>注册时间：</span>
                <span> {{ schoollist.createtime | formatDate }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>幼儿园性质:</span>
                <span>{{ xingzhi }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40" class="form-row">
            <el-col :span="6">
              <div class="form-item">
                <span>平台幼儿数：</span>
                <span>{{ $store.state.schoolInfo.stuCount }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>平台教师数:</span>
                <span>{{ $store.state.schoolInfo.teaCount }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>平台班级数:</span>
                <span>{{ $store.state.schoolInfo.gradeCount }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="30" class="form-row">
            <el-col :span="8">
              <div class="form-item">
                <span>联系人姓名:</span>
                <el-input
                  placeholder="请输入联系人姓名"
                  class="form-item-inp"
                  v-model="rulesForm.contacts"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-item">
                <span>联系人电话:</span>
                <span
                  ><el-input
                    placeholder="请输入联系人电话"
                    class="form-item-inp"
                    v-model="rulesForm.contactsMobile"
                  ></el-input
                ></span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-item">
                <span>联系人邮箱:</span>
                <span
                  ><el-input
                    placeholder="请输入联系人邮箱"
                    class="form-item-inp"
                    v-model="rulesForm.contactsEmail"
                  ></el-input
                ></span>
                <input
                  style="display: block; width: 0px; height: 0px; opacity: 0"
                  placeholder=""
                  resize="none"
                />
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="cont-tit"><span></span>教学活动统计</div>
        <div class="cont-stat">
          <p class="cont-stat-tit">积分统计</p>
          <el-row :gutter="20" class="form-row">
            <el-col :span="6">
              <div class="form-item">
                <span>总积分:</span>
                <span>{{ tongji.totalIntegral }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>园所积分:</span>
                <span>{{ tongji.initIntegral }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>教师积分:</span>
                <span>{{ tongji.teacherIntegral }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>幼儿积分:</span>
                <span>{{ tongji.studentIntegral }}</span>
              </div>
            </el-col>
          </el-row>
          <p class="cont-stat-tip">
            总积分超过了本省<span>{{
              (
                (tongji.provinceRanking / tongji.provinceSchoolCount) *
                100
              ).toFixed(0) + "%"
            }}</span
            >的园所，超过全国<span>{{
              (
                (tongji.nationalRanking / tongji.nationalSchoolCount) *
                100
              ).toFixed(0) + "%"
            }}</span
            >的园所！
          </p>
          <p class="cont-stat-tit">上课统计</p>
          <el-row :gutter="20" class="form-row">
            <el-col :span="6">
              <div class="form-item">
                <span>上课人数:</span>
                <span>{{ tongji.classStuNumber }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>上课次数:</span>
                <span>{{ tongji.courseViews }}</span>
              </div>
            </el-col>
          </el-row>
          <p class="cont-stat-tit">测试统计</p>
          <el-row :gutter="20" class="form-row">
            <el-col :span="6">
              <div class="form-item">
                <span>体质测试人数:</span>
                <span>{{ tongji.bodytestingStunum }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>运动测试人数:</span>
                <span>{{ tongji.sporttestingStunum }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>总测试人数:</span>
                <span>{{ tongji.totalTestingStunum }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="form-row">
            <el-col :span="6">
              <div class="form-item">
                <span>体质测试人次:</span>
                <span>{{ tongji.bodytestingNum }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>运动测试人次:</span>
                <span>{{ tongji.sporttestingNum }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>总测试人次:</span>
                <span>{{ tongji.totalTestingNum }}</span>
              </div>
            </el-col>
          </el-row>
          <p class="cont-stat-tit">评估统计</p>
          <el-row :gutter="20" class="form-row">
            <el-col :span="6">
              <div class="form-item">
                <span>幼儿报告份数:</span>
                <span>{{ tongji.stuReportNum }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>班级报告份数:</span>
                <span>{{ tongji.gradeReportNum }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>园所报告数:</span>
                <span>{{ tongji.schoolReportNum }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-item">
                <span>报告总份数:</span>
                <span>{{ tongji.totalReportNum }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="step-btn">
        <div class="step-btn-pre" @click="confirmPre">上一步</div>
        <div class="step-btn-next" @click="confirmSchoolInfo">确认</div>
      </div>
    </div>
    <div class="step4" v-if="curStep === 'step4'">
      <div class="title">申报状态</div>
      <div class="step4-cont">
        <img :src="declareImg" alt="" class="step4-cont-img" />
        <p class="step4-cont-status">{{ declareStatus }}</p>
        <p class="step4-cont-tip" v-html="declareCont"></p>
        <!-- <p class="step4-cont-tip">如有疑问请拨打客服资讯热线：400-831-9799</p> -->
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog :visible.sync="dialogVideoVisible">
      <video
        v-if="dialogVideoUrl != ''"
        style="width: 100%; height: 300px"
        :src="dialogVideoUrl"
        class="avatar"
        controls="controls"
      >
        您的浏览器不支持视频播放
      </video>
    </el-dialog>
  </div>
</template>
<script>
// import { validatePhone } from "../../shenqing.js";
export default {
  name: "zuteDeclare",
  data() {
    return {
      declareStatus: "", //申报状态
      declareCont: "",
      declareImg: require("@/assets/img/park/Pic_Sbzt_Sbz.png"),
      isRulesList: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      checkStatus: false,
      curStep: "step1",
      certificationList: {
        88: "园所具备签约主体资格,并在系统平台完成实名认证。",
        0: "园所未实名认证,马上认证。",
        1: "实名认证审核中。",
        2: "实名认证失败,重新认证。",
      },
      shiming: 0,
      isWhiteList: true, //是否在白名单
      tongji: {},
      schoolInfo: {},
      //图片回显
      fileList: [],
      //视频回显
      fileVideo: [],
      rulesForm: {
        contacts: "",
        contactsMobile: "",
        contactsEmail: "",
        schoolId: this.$store.state.userInfo.schoolId,
      },
      rulesForm1: {
        teachingConcept: "",
        isRules: 1,
        siteConditions: "",
        roomNum: "",
        footballEquipment: "",
        teachingSituation: "",
        teachingRecords: "",
        schoolHonor: "",
        footballMoney: "",
        schoolPlan: "",
        teachingImages: [],
      },
      jinyong: false,
      youerlu: 0, //判断幼儿录入人数
      achool: {
        teachingConcept: "",
        isRules: "",
        siteConditions: "",
        roomNum: "",
        footballEquipment: "",
        teachingSituation: "",
        teachingRecords: "",
        schoolHonor: "",
        footballMoney: "",
        schoolPlan: "",
        schoolId: this.$store.state.userInfo.schoolId,
      },
      dialogVisible: false,
      dialogVideoVisible: false,
      dialogVideoUrl: "",
      dialogImageUrl: "",
    };
  },
  filters: {
    //毫秒时间转化为yyyy-MM-dd HH:mm:ss
    formatDate: function (seconds) {
      let a = seconds * 1000;
      var datetime = new Date(a);
      var year = datetime.getFullYear(),
        month =
          datetime.getMonth() + 1 < 10
            ? "0" + (datetime.getMonth() + 1)
            : datetime.getMonth() + 1,
        day =
          datetime.getDate() < 10
            ? "0" + datetime.getDate()
            : datetime.getDate(),
        hour =
          datetime.getHours() < 10
            ? "0" + datetime.getHours()
            : datetime.getHours(),
        min =
          datetime.getMinutes() < 10
            ? "0" + datetime.getMinutes()
            : datetime.getMinutes(),
        sec =
          datetime.getSeconds() < 10
            ? "0" + datetime.getSeconds()
            : datetime.getSeconds();
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec
      );
    },
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleVideoPreview(file) {
      this.dialogVideoUrl = file.url;
      this.dialogVideoVisible = true;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isJPG2 = file.type === "image/jpg";
      const isPNG = file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 30;
      if (!isJPG && !isJPG2 && !isPNG) {
        this.$message.warning("只支持jpg或png格式图片");
      }
      if (!isLt5M) {
        this.$message.warning("请上传30MB以内的图片!");
      }
      return (isJPG || isJPG2 || isPNG) && isLt5M;
    },
    // 上传视频成功
    handlesuccess(response, file, fileList) {
      console.log("response", response);
      console.log("file", file.url);
      console.log("fileList", fileList);
    },
    handleImageExceed() {
      this.$message.warning("最多上传3张图片");
    },
    handleVideoExceed() {
      this.$message.warning("最多上传3个视频");
    },
    handleVideosuccess(response, file, fileList) {
      file.url =
        "https://footballcn.oss-cn-beijing.aliyuncs.com/uploads/20210706/9f2c4def2f16655f1c9ea1d7eb2fac89.png";
      console.log("response", response);
      console.log("file", file);
      console.log("fileList", fileList);
    },
    //删除图片
    handleRemove(file) {
      console.log("删除地址", file);
      let url = "";
      if (file.response) {
        url = file.response.data.url;
      } else {
        let arr = file.url.split("com");
        url = arr[1];
      }
      let data = {
        ways: "0",
        url: url,
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.admin.deleteFile(data).then((res) => {
        console.log("提交", res);
      });
    },
    //删除视频
    handleRemovevideo(file) {
      console.log(file);
      let url = "";
      if (file.response) {
        url = file.response.data;
      } else {
        url = file.videoId;
      }
      if (url) {
        let data = {
          ways: "1",
          url: url,
          schoolId: this.$store.state.userInfo.schoolId,
        };
        this.api.admin.deleteFile(data).then((res) => {
          console.log("提交", res);
        });
      }
    },
    beforeAvatarUploadvideo(file) {
      console.log("aaa");
      const isLt10M = file.size / 1024 / 1024 < 30;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过30MB哦!");
        return false;
      }
    },

    handleChange(file, fileList) {
      console.log(fileList, "fileList");
      this.fileList = fileList.slice(-3);
    },
    show() {
      //学校信息
      this.api.admin
        .atteSchool(this.$store.state.userInfo.schoolId)
        .then((res) => {
          this.schoollist = res.data;
          if (res.data.type == "1") {
            this.xingzhi = "私立";
          } else {
            this.xingzhi = "公立";
          }
          this.shiming = res.data.status;
        });
      //申报数据  申报确认数据  申报填写回填
      this.api.admin
        .footballApply(this.$store.state.userInfo.schoolId)
        .then((res) => {
          console.log("资料查询", res.data);
          if (res.flag) {
            this.tongji = res.data;
            //isFinish申报状态  0 未申报  1 申报中 2 申报完成
            //finalState   0 未提交申请 1 待审核 2 已审核通过,3 未通过',
            if (
              res.data.isFinish == 2 ||
              res.data.finalState == 2 ||
              res.data.finalState == 3
            ) {
              if (res.data.finalState == 2) {
                (this.declareStatus = "审核成功"), //申报状态
                  (this.declareCont = "您的园所已经通过审核，成为足特园"),
                  (this.declareImg = require("@/assets/img/park/Pic_Sbzt_Sucess.png"));
              } else if (res.data.finalState == 3) {
                (this.declareStatus = "审核失败"), //申报状态
                  (this.declareCont =
                    "您的园所在本次足特园审核中没有通过审核，希望您继续提升您的足球教学工作<br />祝您下次申报成功！"),
                  (this.declareImg = require("@/assets/img/park/Pic_Sbzt_Fail.png"));
              } else {
                (this.declareStatus = "申报中"), //申报状态
                  (this.declareCont =
                    "您的线上申报已成功，请您耐心等待系统平台客服老师与您联系<br />如有疑问请拨打客服咨询热线：400-831-9799"),
                  (this.declareImg = require("@/assets/img/park/Pic_Sbzt_Sbz.png"));
              }
              this.curStep = "step4";
            } else {
              (this.declareStatus = "申报中"), //申报状态
                (this.declareCont =
                  "您的线上申报已成功，正式进入签约及收付款环节，请您耐心等待系统平台客服老师与您联系<br />如有疑问请拨打客服咨询热线：400-831-9799"),
                (this.declareImg = require("@/assets/img/park/Pic_Sbzt_Sbz.png"));
              this.curStep = "step1";
            }
            // this.shiming = res.data.auditState;
            this.rulesForm.contacts = res.data.contacts;
            this.rulesForm.contactsMobile = res.data.contactsMobile;
            this.rulesForm.contactsEmail = res.data.contactsEmail;
            this.fileList = res.data.imageList;
            this.fileVideo = res.data.videoList || [];
            // console.log(this.fileList,'this.fileList')
            this.rulesForm1.teachingConcept = res.data.teachingConcept;
            this.rulesForm1.isRules = res.data.isRules;
            this.rulesForm1.siteConditions = res.data.siteConditions;
            this.rulesForm1.roomNum = res.data.roomNum;
            this.rulesForm1.footballEquipment = res.data.footballEquipment;
            this.rulesForm1.teachingSituation = res.data.teachingSituation;
            this.rulesForm1.teachingRecords = res.data.teachingRecords;
            this.rulesForm1.schoolHonor = res.data.schoolHonor;
            this.rulesForm1.footballMoney = res.data.footballMoney;
            this.rulesForm1.schoolPlan = res.data.schoolPlan;
            this.rulesForm1.teachingImages = res.data.imageList;
            if (res.data.isEditor == 1) {
              this.jinyong = true;
            } else {
              this.jinyong = false;
            }
          } else {
            this.isWhiteList = false;
          }
        });
    },
    sure(name, value) {
      //input失去焦点时提交数据
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      data[name] = value;
      this.api.admin.updateInput(data).then((res) => {
        console.log("提交", res);
      });
    },
    youer() {
      //判断幼儿录入数量是否大于园所注册数量 提交时给出提示
      this.api.admin
        .findStuRegistered(this.$store.state.userInfo.schoolId)
        .then((res) => {
          this.youerlu = res.data;
        });
    },
    toConfirm() {
      let that = this;
      if (!this.rulesForm1.teachingConcept) {
        this.$notice({
          message: "请阐述幼儿足球活动理念",
        }).isShow();
        return;
      }
      if (!this.rulesForm1.siteConditions) {
        this.$notice({
          message: "请阐述体育场地的情况(运动场面积、场地类型、规格及数量)",
        }).isShow();
        return;
      }
      if (!this.rulesForm1.footballEquipment) {
        this.$notice({
          message: "请阐述幼儿足球活动器材、教具等配备情况",
        }).isShow();
        return;
      }
      if (!this.rulesForm1.teachingSituation) {
        this.$notice({
          message:
            "请阐述师资配备情况(填写教师总数量及具备幼儿足球教师数量及参与进修、培训等情况说明)",
        }).isShow();
        return;
      }
      if (!this.rulesForm1.teachingRecords) {
        this.$notice({
          message: "请阐述幼儿足球教学方式，并对教学活动进行记录",
        }).isShow();
        return;
      }
      if (!this.rulesForm1.schoolHonor) {
        this.$notice({
          message: "请列举园所获取对幼儿足球荣誉",
        }).isShow();
        return;
      }
      if (!this.rulesForm1.footballMoney) {
        this.$notice({
          message: "请列举园所在幼儿足球教学上的经费投入",
        }).isShow();
        return;
      }
      if (!this.rulesForm1.schoolPlan) {
        this.$notice({
          message: "请阐述本学年度园所幼儿足球教学规划",
        }).isShow();
        return;
      }
      this.achool.teachingConcept = this.rulesForm1.teachingConcept;
      this.achool.isRules = this.rulesForm1.isRules;
      this.achool.siteConditions = this.rulesForm1.siteConditions;
      this.achool.roomNum = this.rulesForm1.roomNum;
      this.achool.footballEquipment = this.rulesForm1.footballEquipment;
      this.achool.teachingSituation = this.rulesForm1.teachingSituation;
      this.achool.teachingRecords = this.rulesForm1.teachingRecords;
      this.achool.schoolHonor = this.rulesForm1.schoolHonor;
      this.achool.footballMoney = this.rulesForm1.footballMoney;
      this.achool.schoolPlan = this.rulesForm1.schoolPlan;
      if (this.youerlu == 0) {
        this.$confirm(
          "您的园所信息完善程度将会影响足特园申报，请及时完善相关信息。",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            that.api.nAdmin.newUpdateStep1(this.achool).then((res) => {
              if (res.flag) {
                this.curStep = "step3";
              } else {
                this.$notice({
                  message: res.message,
                }).isShow();
              }
            });
          })
          .catch(() => {});
      } else {
        this.$confirm(
          "您将提交最终申报资料，提交后申报资料不可修改。是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            that.api.nAdmin.newUpdateStep1(this.achool).then((res) => {
              if (res.flag) {
                this.curStep = "step3";
              } else {
                this.$notice({
                  message: res.message,
                }).isShow();
              }
            });
          })
          .catch(() => {});
      }
    },
    declarePre() {
      //申报填写
      this.curStep = "step1";
    },
    confirmSchoolInfo() {
      if (!this.rulesForm.contacts) {
        this.$notice({
          message: "请输入联系人姓名",
        }).isShow();
        return;
      } else {
        if (!this.common.textNumberCheck(this.rulesForm.contacts, 25)) {
          this.$notice({
            message: "请输入正确格式联系人姓名",
          }).isShow();
          return;
        }
      }
      if (!this.rulesForm.contactsMobile) {
        this.$notice({
          message: "请输入联系人电话",
        }).isShow();
        return;
      } else {
        if (!this.common.isMobile(this.rulesForm.contactsMobile, 25)) {
          this.$notice({
            message: "请输入正确格式联系人电话",
          }).isShow();
          return;
        }
      }
      if (!this.rulesForm.contactsEmail) {
        this.$notice({
          message: "请输入联系人邮箱",
        }).isShow();
        return;
      } else {
        if (!this.common.validateLength(this.rulesForm.contactsEmail, 25)) {
          this.$notice({
            message: "请输入正确格式联系人邮箱",
          }).isShow();
          return;
        } else {
          if (!this.common.isEmail(this.rulesForm.contactsEmail)) {
            this.$notice({
              message: "请输入正确格式联系人邮箱",
            }).isShow();
            return;
          }
        }
      }
      //申报确认 提交联系人信息
      this.api.nAdmin.newUpdateStep2(this.rulesForm).then((res) => {
        if (res.flag) {
          this.curStep = "step4";
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    confirmPre() {
      //申报确认 上一步
      this.curStep = "step2";
    },
    renzhneg() {
      if (this.shiming == 0 || this.shiming == 2) {
        this.$router.push({
          path: "/user",
        });
      }
    },
    startDeclare() {
      if (
        this.isWhiteList &&
        (this.shiming == 88 || this.shiming == 1) &&
        this.checkStatus
      ) {
        this.curStep = "step2";
      }
    },
  },
  created() {
    this.show();
    this.youer();
  },
};
</script>
<style scoped lang="scss">
.main {
  padding: 0 40px 50px 40px;
  // width:1120px;
  background: #ffffff;
  color: #333333;

  .mt30 {
    margin-top: 30px;
  }
  .title {
    padding: 40px 0;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .step-btn {
    margin-top: 40px;
    @include flex();
    font-size: 18px;
    .step-btn-pre {
      width: 98px;
      height: 36px;
      border: 1px solid #ba3037;
      border-radius: 6px;
      color: #ba3037;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
    }
    .step-btn-pre:hover {
      background: #ff5e65;
      color: #ffffff;
      border: 1px solid #ff5e65;
    }
    .step-btn-next {
      margin-left: 64px;
      width: 112px;
      height: 56px;
      background: url("../../../assets/img/park/Btn_Bg_Next_U.png") no-repeat;
      background-size: cover;
      text-align: center;
      line-height: 56px;
      color: #ffffff;
      cursor: pointer;
    }
    .step-btn-next:hover {
      background: url("../../../assets/img/park/Btn_Bg_Next_H.png") no-repeat;
      background-size: cover;
    }
  }
  .declare-check {
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    span {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url("../../../assets/img/park/Icon_Xz_Uncho.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 10px;
    }
    .active {
      background: url("../../../assets/img/park/Icon_Xz_Cho.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .declare-tip {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: #ba3037;
  }
  .step1 {
    .cont {
      .cont-tit {
        // margin-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
        line-height: 48px;

        .manual {
          display: inline-block;
          width: 114px;
          height: 46px;
          line-height: 48px;
          background: url("../../../assets/img/park/Btn_Bg_Czzn_U.png")
            no-repeat;
          background-size: cover;
          font-size: 14px;
          color: #ffffff;
          > a {
            color: #ffffff;
          }

          > img {
            margin-top: -5px;
            width: 23px;
            height: 23px;
            padding-left: 10px;
            padding-right: 4px;
            vertical-align: middle;
          }
        }
      }
      .cont-paragraph {
        font-size: 16px;
        line-height: 28px;
        white-space: normal;
      }
      .cont-demand {
        margin-bottom: 15px;
        font-size: 18px;
      }
      .cont-undemand {
        font-weight: bold;
        color: #ba3037;
        opacity: 0.9;
        cursor: pointer;
      }
      .noPointer {
        cursor: default;
      }
    }
    .start-declare {
      margin-top: 40px;
      text-align: center;

      .start-declare-btn {
        margin: 16px auto 0 auto;
        width: 232px;
        height: 56px;
        text-align: center;
        line-height: 56px;
        background: url("../../../assets/img/park/Btn_Bg_Sb_U.png") no-repeat;
        background-size: cover;
        color: #ffffff;
        font-size: 18px;
        cursor: pointer;
      }
      .undeclare {
        margin: 16px auto 0 auto;
        width: 232px;
        height: 56px;
        text-align: center;
        line-height: 56px;
        background: url("../../../assets/img/park/Btn_Bg_Sb_Gray.png") no-repeat;
        background-size: cover;
        color: #ffffff;
        font-size: 18px;
        cursor: not-allowed;
      }
      .start-declare-btn:hover {
        background: url("../../../assets/img/park/Btn_Bg_Sb_H.png") no-repeat;
        background-size: cover;
      }
    }
  }
  .step2 {
    color: #333333;
    .form-tit {
      font-size: 20px;
      font-weight: bold;
    }
    .mt30 {
      margin-top: 30px;
    }
    .form-row {
      margin-top: 20px;

      .form-item {
        font-size: 16px;
        .form-item-tit {
          font-size: 16px;
          @include flex("row", "flex-start", "center");
          > span {
            padding-top: 3px;
            padding-right: 10px;
            color: #ff0000;
            font-size: 18px;
            font-weight: 400;
          }
        }
      }
      .form-item-inp {
        margin-top: 6px;
      }
      .form-item-tips {
        margin-top: 10px;
        font-size: 14px;
        color: #ba3037;
      }
    }
    .avatar-uploader-icon {
      border: 1px dashed #dddddd;
      border-radius: 8px;
      font-size: 28px;
      color: #8c939d;
      width: 122px;
      height: 142px;
      line-height: 142px;
      text-align: center;
      margin-bottom: 40px;
    }
    .avatar {
      width: 122px;
      height: 142px;
      display: block;
    }
  }
  .step3 {
    color: #333333;
    .cont-tit {
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;

      > span {
        margin-right: 12px;
        display: inline-block;
        background: #ba3037;
        border-radius: 2px;
        width: 4px;
        height: 18px;
      }
    }
    .form-item {
      margin-bottom: 15px;
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      > span:nth-child(1) {
        padding-right: 12px;
        color: #888888;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .form-item-inp {
        width: 228px;
      }
    }
    .cont-stat {
      .cont-stat-tit {
        margin-bottom: 15px;
        font-size: 20px;
        color: #ba3037;
      }
      .cont-stat-tip {
        margin-bottom: 15px;
        font-size: 16px;
        span {
          font-size: 20px;
          font-weight: bold;
          color: #ffa000;
        }
      }
    }
  }
  .step4-cont {
    text-align: center;
    .step4-cont-img {
      width: 124px;
      height: 124px;
    }
    .step4-cont-status {
      margin-top: 15px;
      margin-bottom: 30px;
      font-size: 18px;
    }
    .step4-cont-tip {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
</style>
<style lang="scss">
.form-item-inp .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
.form-item-inp .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: none;
  border: none;
  > img {
    width: 80px;
    height: 80px;
  }
}
</style>